import {CommitHash} from '@shared/dynamo_model';
import {EnvConstants} from '@shared/model/env_constants';

export const COMMIT_HASH = '15a5f405e93424d3a6ac7aa7d5181aafe4cda5f6' as CommitHash;
export const IS_LOCALHOST_ENV = false as boolean;
export const IS_DEVELOPMENT_ENV = true as boolean;
export const IS_PRODUCTION_ENV = false as boolean;
export const env: EnvConstants = {
  type: 'devs',
  region: 'eu-west-1',
  onescaleDomainName: 'onescale-devs.com',
  cidrBlock: '10.0.0.0/16',
  accountId: '1o75yqwwoubp6wgq',
  stripe: {
    apiPublishableKey:
      'pk_test_51NhuyvJHsX41O715BDbwm6zxaHxkvO0OeMEt6UtYQuM7T4z68mRnOTHdknjWVYJdOhiFjeebk8x6BeHWkNu46WuM00Fqt1mxBi',
    liveMode: false,
  },
  expertTicket: {
    baseUrl: 'https://pre-portaventuraworld.api.experticket.com/api',
    partnerId: '8ja1sdeifghtc',
    apiVersion: '3.54',
  },
  googleMapsApiKey: 'AIzaSyDQ3VD_ybEGMknTAUxQNaIvVnxbbR45lPU',
} as EnvConstants;
